import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`So you didn't hear it from us.`}</p>
    <p>{`Well, okay, I guess you are hearing it from us if you're reading this on our blog.`}</p>
    <p>{`But it was the "panel of Prepaid industry experts" at the Prepaid Expo who said it:
`}<strong parentName="p">{`PayPerks is the `}<a parentName="strong" {...{
          "href": "http://www.iirusa.com/prepaid/next-big-thing.xml"
        }}>{`Next Big Thing`}</a></strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADnklEQVQozwGTA2z8ANCleM2heMqbb9moe76Ubks6Lk8+NYZ0aJKAc5KBdo17bZB+caGMfYd1Y3NhT3VjUXViUHJgTWdWR6N6VwC+jGHDkmvPoXTktYXJn3lALig9LSZFMi2WgXhjXFtGUFyDe3mbioCWhnuNfnKCc2hvXUxsWUpdSjygdlEAwZFnyp122a6C6byPsI1qZ1lHalxKbV9NkIBse3Fhc2tegXtthoBxj4d3mI+AnZSDmIZuj39khHNcrYRbAMqddM6kfezBlNm+lndsWnhoW3lqXntrXnhoW35uYINxY4d0Y459a5iHc56Ld5yJdpeFc5F+bYZ0ZayDXADXq37bson12arj3byJemOAcGq1pJqvoZm0pJu0o5q2pp25rKG/rqPEsqbHtanFs6jCsaLIsqWWg3uZcEcA0qd61ax+9+nG4eHhX0s3l4qC3LiszY+I0ZuR1qic16ec1ZmP3LCk3K+j26KX2aCT1pyS5sW1rpqQmW9KAMqdb8KSYOfKpuHf4VtKO4x9d820qsGakcikmMmroMyqoMqgls6rntGypM2lmc2ll8qdj9q8rKOPhJZtRgDGmmTezbfw6eFuX1QfExNJNCyag3Wwoparm46nloqzoJWzoZSvnI20no+wm4uynYy1opHFrp2diH6TbEUAu3FS15qJvJOQIRQRHBISVC0cjGlXwrCosp+VtKGVu6aZuaKTuqOTtZ2QuaGStp6Oxqyc1buql4R7kWlFAIRlYXFMQ0onHx8UEygVFWAqHYFhUq6elb6omrSfkamThq6Yi7egkceuoMatoMiunsasnNC2pZJ+dY1nQQCShn+Ui4NCNjAZDQ0+IhpPIReRfHK8qZy2no6slYebhHiehnyfioCpkYqwl42vl4y+pZXKsJ+LeG+GYz0AyLCmo41/JxkXIxISUSobHA4NSz04rZaGuKGSr5aEq5N/rJGAs5uKu6SUuaGSuaGRu6CRxauZiHVrg2E8ANW7rruhgCwgGkQhGqBnR1c8KhAGBXdgUb+nlaGIdoxzZZR7bJuEd7GajbeekLqhkLeejcKnlYRxZ4BeOwC+ppbKqYY7LydMLiKjZkZ8WUESDAx6Y1HApZCmjn6ki36kiXumjoKmjoOxmYu3nY20moq+o5KCbWV4Uy0Av6KRyqWHalNDi3BYYkMtJhwYCQcLe2RMvqSKmoFxh29aiXBdoYd2tZuMspmJsZiIspeHu6CPf2thVyoXI4DZTeqg0mkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/e87ffd9f9efb670de9ae65dd946a4109/7f61c/arlyn-prepaidexpo-nextbigthing.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/e87ffd9f9efb670de9ae65dd946a4109/b0a15/arlyn-prepaidexpo-nextbigthing.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/e87ffd9f9efb670de9ae65dd946a4109/e17e5/arlyn-prepaidexpo-nextbigthing.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/e87ffd9f9efb670de9ae65dd946a4109/0b533/arlyn-prepaidexpo-nextbigthing.png 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/e87ffd9f9efb670de9ae65dd946a4109/0b533/arlyn-prepaidexpo-nextbigthing.png",
            "alt": "arlyn prepaidexpo nextbigthing",
            "title": "arlyn prepaidexpo nextbigthing",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`We are extremely excited about being selected for this honor and to have the opportunity to demo PayPerks to the audience of the `}<a parentName="p" {...{
        "href": "http://www.iirusa.com/prepaid/"
      }}>{`Prepaid Expo`}</a>{` in Las Vegas today.`}</p>
    <p>{`If you're milling around the conference or looking for an excuse to pull yourself away from the Poker tables, stop by the `}<strong parentName="p">{`Exhibit Hall at 2PM to watch Arlyn and Caroline give our demo`}</strong>{`...and then stay to say hi to them after—they are friendly!`}</p>
    <p>{`Arlyn and I would like to thank everyone at PayPerks for helping us achieve this milestone. We all work hard to embody all three of the criteria for this award (game changer, customer centric, and advancing prepaid) on a daily basis and it's really nice when others see that too.`}</p>
    <p>{`More photos to come later today/tomorrow.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      